import { useTranslation } from "next-i18next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import Head from "next/head"
import Link from "next/link"

const getMetadata = () => {
    return (
        <Head>
            <title>404</title>
        </Head>
    )
}

export default function PageNotFound() {
    const { t } = useTranslation()

    return (
        <>
            {getMetadata()}
            <div className="flex justify-center w-max-[80rem] mr-2 ml-2">
                <div className="flex flex-col justify-center items-center text-center font-poppins">
                    <h1 className="text-6xl">404</h1>
                    <p>{t('common.page_not_found')}</p>
                    <Link href={{pathname: "/"}}>
                        <button className="bg-primary text-white p-2 rounded-lg mt-5">{t('common.back')}</button>
                    </Link>
                </div>
            </div>
        </>
    )
}

export const getStaticProps = async ({ locale }) => ({
    props: {
      ...(await serverSideTranslations(locale, ['common', 'post'])),
    },
})